import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Input,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import {useFilters, useGlobalFilter, usePagination, useSortBy, useTable,} from 'react-table';
import {useTranslation} from 'react-i18next';
import SortingIndicators from './SortIndicators';
import {MouseEvent, useEffect, useMemo} from 'react';
import i18n from '../i18n';
import {BUILD_COLUMNS, Structures} from './ColumnsTable';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  DownloadIcon,
  EditIcon,
} from '@chakra-ui/icons';

type ActionButton =
  | 'DOWNLOAD'
  | 'CONTINUE'
  | 'ICON'
  | 'EDIT'
  | 'DELETE';

const TableComponent = ({
    sortable = true,
    paginable = true,
    hoverRow = false,
    includeActionButton = true,
    onClickIcon = () => {
    },
    onClickDelete = () => {
    },
    onClickContinue = () => {
    },
    includeCheckboxes = true,
    onClickCheckboxHeader = () => {
    },
    isCheckedCheckboxHeader = () => {
    },
    onClickCheckboxBody = () => {
    },
    isCheckedCheckboxBody = () => {
    },
    onClickDownload = () => {
    },
    columns,
    data,
    addFilter,
    addGlobalFilter,
    getTableState,
    hiddenColumns = [],
    actionButtonType = 'ICON',
    colorSelectedRow,
    selectedRowId,
    sortBy = [],
  }: {
    sortable?: boolean;
    paginable?: boolean;
    hoverRow?: boolean;
    addFilter?: { column: string; value: boolean | undefined };
    addGlobalFilter?: unknown;
    includeActionButton?: boolean;
    onClickIcon?: any;
    onClickDelete?: any;
    onClickContinue?: any;
    includeCheckboxes?: boolean;
    onClickCheckboxHeader?;
    isCheckedCheckboxHeader?;
    onClickCheckboxBody?;
    isCheckedCheckboxBody?;
    onClickDownload?;
    columns: { structure: Structures; texts: string };
    data: any[];
    getTableState?;
    hiddenColumns?: string[];
    actionButtonType?: ActionButton | ActionButton[];
    colorSelectedRow?: boolean;
    selectedRowId?;
    sortBy?: { id: string; desc: boolean }[];
  }) => {
  const [t] = useTranslation();

  const columnsToRender = useMemo(
    () =>
      BUILD_COLUMNS(
        columns.structure,
        t(columns.texts, {returnObjects: true})
      ),
    [i18n.language]
  );

  const tableInstance = useTable(
    {
      columns: columnsToRender,
      data,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetHiddenColumns: false,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setGlobalFilter,
    setFilter,
    setAllFilters,
    canNextPage,
    gotoPage,
    previousPage,
    canPreviousPage,
    nextPage,
    pageOptions,
    pageCount,
    state,
    page,
    setHiddenColumns,
    setSortBy,
  } = tableInstance;

  const {pageIndex} = state;

  useEffect(() => {
    setGlobalFilter(addGlobalFilter || '');
  }, [addGlobalFilter]);

  useEffect(() => {
      gotoPage(0);
      }, [addGlobalFilter]);

  useEffect(() => {
    if (addFilter?.column && addFilter?.value !== undefined) {
      setFilter(addFilter.column, String(addFilter.value));
    } else {
      setAllFilters([]);
    }
  }, [addFilter]);

  useEffect(() => {
    if (getTableState) getTableState(state);
  }, [getTableState]);

  useEffect(() => {
    if (sortBy) setSortBy(sortBy);
    setHiddenColumns(hiddenColumns);
  }, []);

  /*
    If the element returns as a class name an object different than a string, then it is a child of the checkbox.
    Also, if it returns an string but in the class it contains the name 'checkbox' then it is part of the checkbox too.
  */
  const validateIsNotCheckbox = (
    e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>
  ) => {
    const className = e.target['className'];
    return typeof className === 'string' && !className.includes('checkbox');
  };

  const renderActionButton = (
    actionButtonType: ActionButton | ActionButton[],
    row
  ) => {
    const ACTION_BUTTONS = {
      ICON: (
        <ChevronRightIcon
          key='ICON'
          fontSize={24}
          color="#304B99"
          cursor="pointer"
          onClick={() =>
            onClickIcon(columns.structure === 'REPORTS' ? row : row.values)
          }
        />
      ),
      CONTINUE: (
        <Button
          color="black"
          bg={'white'}
          boxShadow="md"
          variant="outline"
          borderColor="gray.300"
          fontSize={14}
          h={8}
          px={5}
          mx={2}
          key='CONTINUE'
          onClick={() => onClickContinue(row)}
        >
          {t('table.actions.reports.continue')}
        </Button>
      ),
      EDIT: (
        <Button
          color="black"
          bg={'white'}
          boxShadow="md"
          variant="outline"
          borderColor="gray.300"
          fontSize={14}
          h={8}
          px={0}
          mx={2}
          aria-label={t('table.actions.reports.continue')}
          key='EDIT'
          onClick={() => onClickContinue(row)}
        >
          <EditIcon/>
        </Button>
      ),
      DOWNLOAD: (
        <Button
          color="black"
          bg={'white'}
          boxShadow="md"
          variant="outline"
          borderColor="gray.300"
          fontSize={14}
          h={8}
          px={0}
          mx={2}
          key='DOWNLOAD'
          onClick={() => onClickDownload(row)}
        >
          <DownloadIcon/>
        </Button>
      ),
      DELETE: (
        <Button
          color="black"
          bg={'white'}
          boxShadow="md"
          variant="outline"
          borderColor="gray.300"
          fontSize={14}
          h={8}
          px={0}
          mx={2}
          aria-label="button-delete"
          key='DELETE'
          onClick={() => onClickDelete(row)}
        >
          <DeleteIcon/>
        </Button>
      ),
    };

    return Array.isArray(actionButtonType) ? (
      <Td borderColor="#D0DBE6">
        {actionButtonType.map((actionButton) => ACTION_BUTTONS[actionButton])}
      </Td>
    ) : (
      <Td borderColor="#D0DBE6">{ACTION_BUTTONS[actionButtonType] || <></>}</Td>
    );
  };

  return (
    <>
      <Table
        size="sm"
        variant="simple"
        align="center"
        border="1px"
        borderColor="#D0DBE6"
        height="20px"
        wordBreak="break-all"
        whiteSpace="nowrap"
        {...getTableProps()}
      >
        <Thead top="0px" bg="#304B99" height={4}>
          {headerGroups.map((headerGroup, indexKey) => (
            <Tr p="0" key={indexKey} {...headerGroup.getHeaderGroupProps()}>
              {includeCheckboxes && (
                <Th w="5%">
                  <Checkbox
                    isChecked={isCheckedCheckboxHeader(
                      paginable ? page : undefined
                    )}
                    onChange={(e) =>
                      onClickCheckboxHeader(
                        e.target.checked,
                        paginable ? page : undefined
                      )
                    }
                  />
                </Th>
              )}
              {headerGroup.headers.map((column, columnIndex) => (
                <Th
                  p="2.5"
                  key={`${columns.structure}-${columnIndex}`}
                  textTransform="capitalize"
                  color={'white'}
                  {...column.getHeaderProps(
                    sortable && column.getSortByToggleProps()
                  )}
                >
                  {column.render('Header')}
                  {sortable && (
                    <SortingIndicators
                      isSorted={column.isSorted}
                      isSortedAsc={!column.isSortedDesc}
                      isSortedDesc={column.isSortedDesc}
                    />
                  )}
                </Th>
              ))}
              {includeActionButton && (
                <Th
                  p="2.5"
                  color={'white'}
                  textTransform="capitalize"
                  w="5%"
                ></Th>
              )}
            </Tr>
          ))}
        </Thead>
        <Tbody p="2em" {...getTableBodyProps()}>
          {paginable &&
            page &&
            page.length > 0 &&
            page.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  key={row.id}
                  padding={5}
                  cursor="pointer"
                  background={
                    colorSelectedRow && selectedRowId === row.values.identifier
                      ? '#92C7EC'
                      : 'unset'
                  }
                  _hover={
                    hoverRow && {
                      background: '#92C7EC',
                    }
                  }
                  onClick={(e) => {
                    if (validateIsNotCheckbox(e)) {
                      onClickIcon(
                        columns.structure === 'REPORTS' ? row : row.values
                      );
                    }
                  }}
                  {...row.getRowProps()}
                >
                  {includeCheckboxes && (
                    <Td borderColor="#D0DBE6">
                      <Checkbox
                        aria-label="test"
                        isChecked={isCheckedCheckboxBody(
                          columns.structure === 'REPORTS'
                            ? row.index
                            : row.original.id,
                          columns.structure === 'BY_FOLDER'
                            ? row.original.type
                            : undefined
                        )}
                        onChange={(e) =>
                          onClickCheckboxBody(
                            e.target.checked,
                            columns.structure === 'REPORTS'
                              ? row.index
                              : row.original.id,
                            columns.structure === 'BY_FOLDER'
                              ? row.original.type
                              : undefined
                          )
                        }
                      />
                    </Td>
                  )}
                  {row.cells.map((cell) => {
                    return (
                      <Td key={`${cell.row.id}-${cell.column.id}`} borderColor="#D0DBE6" p={3} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                  {includeActionButton &&
                    renderActionButton(actionButtonType, row)}
                </Tr>
              );
            })}
          {!paginable &&
            rows &&
            rows.length > 0 &&
            rows.map((row) => {
              prepareRow(row);
              return (
                <Tr
                  padding={5}
                  _hover={
                    hoverRow && {
                      background: '#92C7EC',
                    }
                  }
                  {...row.getRowProps()}
                >
                  {includeCheckboxes && (
                    <Td borderColor="#D0DBE6">
                      <Checkbox
                        isChecked={isCheckedCheckboxBody(
                          columns.structure === 'REPORTS'
                            ? row.index
                            : row.original.id,
                          columns.structure === 'BY_FOLDER'
                            ? row.original.type
                            : undefined
                        )}
                        onChange={(e) =>
                          onClickCheckboxBody(
                            e.target.checked,
                            columns.structure === 'REPORTS'
                              ? row.index
                              : row.original.id,
                            columns.structure === 'BY_FOLDER'
                              ? row.original.type
                              : undefined
                          )
                        }
                      />
                    </Td>
                  )}
                  {row.cells.map((cell) => {
                    return (
                      <Td borderColor="#D0DBE6" p={3} {...cell.getCellProps()}>
                        {cell.render('Cell')}
                      </Td>
                    );
                  })}
                  {includeActionButton &&
                    renderActionButton(actionButtonType, row)}
                </Tr>
              );
            })}

          {(!rows || rows.length <= 0 || !page || page.length <= 0) && (
            <Tr>
              <Td
                textAlign="center"
                fontSize="1em"
                mx="auto"
                colSpan={
                  rows.length +
                  (includeCheckboxes ? 1 : 0) +
                  (includeActionButton ? 1 : 0)
                }
              >
                {t('table.noData')}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {paginable && (
        <Flex my={3}>
          <Spacer/>
          <Flex alignContent="center">
            <IconButton
              _focus={{boxShadow: ''}}
              _hover={{backgroundColor: ''}}
              _active={{backgroundColor: ''}}
              color="gray.800"
              bg="#transparent"
              fontSize="15px"
              icon={<ArrowLeftIcon/>}
              disabled={!canPreviousPage}
              onClick={() => gotoPage(0)}
              aria-label="close"
            />
            <IconButton
              _focus={{boxShadow: ''}}
              _hover={{backgroundColor: ''}}
              _active={{backgroundColor: ''}}
              color="gray.800"
              bg="#transparent"
              fontSize="30px"
              icon={<ChevronLeftIcon/>}
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
              aria-label="close"
            />
            <Text m="0" alignSelf="center">
              {pageIndex + 1} - {pageOptions.length}
            </Text>
            <IconButton
              _focus={{boxShadow: ''}}
              _hover={{backgroundColor: ''}}
              _active={{backgroundColor: ''}}
              color="gray.800"
              bg="#transparent"
              fontSize="30px"
              icon={<ChevronRightIcon/>}
              disabled={!canNextPage}
              onClick={() => nextPage()}
              aria-label="close"
            />
            <IconButton
              _focus={{boxShadow: ''}}
              _hover={{backgroundColor: ''}}
              _active={{backgroundColor: ''}}
              color="gray.800"
              bg="transparent"
              fontSize="15px"
              icon={<ArrowRightIcon/>}
              disabled={!canNextPage}
              onClick={() => gotoPage(pageCount - 1)}
              aria-label="close"
            />
            <Text
              m="0"
              alignSelf="center"
              borderRightColor=""
              defaultChecked={pageIndex + 1}
              borderColor="gray.300"
              fontSize="sm"
              whiteSpace="nowrap"
            >
              {t('table.pagination.page')}
            </Text>
            <Input
              mx="5px"
              alignSelf="center"
              borderColor="gray.600"
              onChange={(e) => {
                let pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(pageNumber);
              }}
              w="10%"
              size="sm"
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default TableComponent;
