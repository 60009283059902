import {Box} from '@chakra-ui/react';
import {ReferenceRegistrationGenderEnum, ReferenceRegistrationSampleTypeEnum,} from '@hmf/portal-api-client';
import {Form, Formik} from 'formik';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import {ChakraDatePicker, InputFormik, NumberInputFormik, RadioGroupChakraFormik, SelectFormik,} from '../../Formik';
import {Option} from '../../Formik/SelectFormik';
import {ReferenceSampleFormDto, useReferenceSampleForm,} from '../hooks/useReferenceSampleForm';
import {ReferenceSampleFormProps} from '../interfaces/StepReferenceSampleRegistration';
import {PrefixedInputFormik} from "../../Formik/PrefixedInputFormik";
import {checkSpecialCharacters} from "../../../stores/data";

export const UNKNOWN_CONTRACT_OPTION_VALUE = "Unknown";

const ReferenceSampleForm: React.FC<ReferenceSampleFormProps<ReferenceSampleFormDto>> =
  observer(({dataStore, innerRef, onValidate}) => {
    const [t] = useTranslation();
    const {
      initialValues,
      onSubmit,
      hospitalOptions,
      validationSchema,
      showConcentration,
      onChangeHospital
    } = useReferenceSampleForm(dataStore);

    return (
      <Formik
        initialValues={initialValues}
        innerRef={innerRef}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({values, isValid, dirty, setFieldValue, setFieldTouched}) => {
          onValidate(isValid && dirty);
          return (
            <Form>
              <Box padding={5}>
                <Box w="sm" mt="2">
                  <SelectFormik
                    placeholder={t('referenceRegistrationProcess.registrationSetup.placeholders.hospital')}
                    label={t('referenceRegistrationProcess.registrationSetup.labels.hospital')}
                    name="hospitalId"
                    mt="5"
                    isDisabled={false}
                    options={hospitalOptions.map<Option>((hospitalOption) => {
                      return {
                        label: hospitalOption.name,
                        value: hospitalOption.id.toString(),
                      };
                    })}
                    isRequired={true}
                    onChange={(e) => {
                      onChangeHospital(Number(e.value))
                    }}
                  />
                </Box>
                {values.hospitalId !== undefined && (
                  <Box mt="5" w="sm">
                    <RadioGroupChakraFormik
                      label={t('referenceRegistrationProcess.registrationSetup.labels.contractCode')}
                      name="contractCode"
                      isDisabled={false}
                      options={[...dataStore.contractOptions.map<Option>(
                        ({name, contractCode}) => ({
                          label: name,
                          value: contractCode
                        })
                      ), {label: "Unknown Diagnostics", value: UNKNOWN_CONTRACT_OPTION_VALUE}]}
                      isRequired={true}
                      mb={4}
                      onChange={(e) => {
                        if (values.hospitalId !== undefined) {
                          values.studyIdPrefix = dataStore.getStudySampleIdStart(values.hospitalId, e.target.value, t);
                          setFieldValue('patientId', "", true);
                          setFieldTouched('patientId', false);
                        }
                      }}
                    />
                  </Box>)
                }
                {values.contractCode.length > 0 && (dataStore.isDiagnostic(values.contractCode)
                    ? <>
                      <Box mt="5" w="sm">
                        <InputFormik
                          placeholder={t('referenceRegistrationProcess.registrationSetup.placeholders.hospitalPatientId')}
                          label={t('referenceRegistrationProcess.registrationSetup.labels.hospitalPatientId')}
                          isDisabled={false}
                          name="patientId"
                          isRequired={true}
                        />
                      </Box>
                    </>
                    : <>
                      <Box mt="5" w="sm">
                        <PrefixedInputFormik
                          placeholder={t('referenceRegistrationProcess.registrationSetup.placeholders.studyPatientId')}
                          label={t('referenceRegistrationProcess.registrationSetup.labels.studyPatientId')}
                          isDisabled={false}
                          name="patientId"
                          isRequired={true}
                          prefix={values.studyIdPrefix}
                        />
                      </Box>
                    </>
                )}
                {values.contractCode.length > 0 && dataStore.isDiagnostic(values.contractCode) && (
                  <>
                    <Box mt="5" w="sm">
                      <InputFormik
                        label={t('referenceRegistrationProcess.patientInfo.labels.currentSurname')}
                        name="currentSurname"
                        isDisabled={false}
                      />
                    </Box>
                    <Box w="sm" mt="5">
                      <ChakraDatePicker
                        label={t('referenceRegistrationProcess.patientInfo.labels.dateOfBirth')}
                        name="dateOfBirth"
                        disabled={false}
                      />
                    </Box>
                    <Box w="sm" mt="5">
                      <RadioGroupChakraFormik
                        label={t('referenceRegistrationProcess.patientInfo.labels.gender')}
                        name="gender"
                        isDisabled={false}
                        options={Object.entries(
                          ReferenceRegistrationGenderEnum
                        ).map(([, value]) => ({
                          label: t(`genders.${value.toLowerCase()}`),
                          value: value
                        }))}
                      />
                    </Box>
                  </>
                )}
                {values.contractCode.length > 0 && (
                  <>
                    <Box w="sm" mt="5">
                          <ChakraDatePicker
                              label={t('referenceRegistrationProcess.patientInfo.labels.bloodSamplingDate')}
                              name="samplingDate"
                              disabled={false}
                              maxDate={new Date()}
                          />
                    </Box>
                    <Box mt="5" w="sm">
                      <InputFormik
                        label={t('referenceRegistrationProcess.sampleInfo.labels.barcode')}
                        name="barcode"
                        isDisabled={false}
                        isRequired={true}
                        onKeyDown={(e) => {checkSpecialCharacters(e)}}
                      />
                    </Box>
                    <Box mt="5">
                      <RadioGroupChakraFormik
                        label={t('referenceRegistrationProcess.sampleInfo.labels.sampleType')}
                        isDisabled={false}
                        name="sampleType"
                        options={Object.entries(ReferenceRegistrationSampleTypeEnum
                        ).map(([, value]) => ({
                          label: t(`sampleTypes.${value.toLowerCase()}`),
                          value: value
                        }))}
                        isRequired={true}
                      />
                    </Box>
                    {showConcentration(values.sampleType) && (
                      <Box mt="5" w="sm">
                        <NumberInputFormik
                          label={t('referenceRegistrationProcess.sampleInfo.labels.concentration')}
                          name="concentration"
                          isDisabled={false}
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    );
  });

export default ReferenceSampleForm;
